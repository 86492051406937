<template>
    <div></div>
  </template>
  
  <script>
  import { driver } from "driver.js";
  import "driver.js/dist/driver.css";
  import { mapActions, mapGetters } from "vuex";
  import Cookies from 'js-cookie';
  
  export default {
    name: "TourGuide",
    props: {
      steps: {
        type: Array,
        default: () => []
      },
      type: {
        type: String,
        default: 'student',
        validator: (value) => ['student', 'admin'].includes(value)
      }
    },
    data() {
      return {
        driverObj: null
      };
    },
    computed: {
      ...mapGetters({
        getMe: "auth/getMe",
        getIsMobile: "layout/getIsMobile",
      }), 
    },
    methods: {
      ...mapActions({
        actionShowModalTermsOfUse: "home/actionShowModalTermsOfUse",
        actionShowModalFirstAccess: "home/actionShowModalFirstAccess",
    }),
      startTour() {

        this.steps = this.getIsMobile
          ? this.steps.map(step => ({ ...step, element: null }))
          : this.steps;

        this.driverObj = driver({
          popoverClass: "driverjs-theme",
          overlayColor: "#454545",
          overlayOpacity: 0.8,
          showProgress: true,
          allowClose: false,
          progressText: "{{current}} de {{total}}",
          showButtons: ["next", "previous", "close"],
          steps: this.steps,
          onDestroyed: () => {
            Cookies.set('tourGuideCompleted', 'true');
          },
        });

        this.showTour(this.driverObj);
      },
      moveNext() {
        this.driverObj.moveNext();
      },
      movePrevious() {
        this.driverObj.movePrevious();
      },
      shouldShowTourGuideForStudent() {
        const isStudent = this.getMe?.member?.role === 'student';
        const hasCompletedTour = Cookies.get('tourGuideCompleted') === 'true';
        return isStudent && !hasCompletedTour;
      },
      shouldShowTourGuideForAdmin() {
        const isAdmin = this.getMe?.member?.role === 'admin' || this.getMe?.member?.role === 'owner';
        const hasCompletedTour = Cookies.get('tourGuideCompleted') === 'true';
        return isAdmin && !hasCompletedTour;
      },
      async showTour(driverObj) {
        try {
          const [shouldShowTerms, shouldShowFirstAccess] = await Promise.all([
            this.actionShowModalTermsOfUse(),
            this.actionShowModalFirstAccess()
          ]);

          const noModalsActive = !shouldShowTerms && !shouldShowFirstAccess;
          
          if(this.type === 'admin'){
            if (noModalsActive && this.shouldShowTourGuideForAdmin()) {
              driverObj?.drive();
            }
          }else{
            if (noModalsActive && this.shouldShowTourGuideForStudent()) {
              driverObj?.drive();
            }
          }

        } catch (error) {
          console.error('Failed to initialize tour:', error);
        }
      }
    }
  };
  </script>
  
<style lang="scss">

.driver-popover.driverjs-theme {
  display: flex !important;
  max-width: 441px;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 8px #0003;

  [data-theme="dark"] &{
    background-color: #000;
  }
}


.driver-popover.driverjs-theme  .driver-popover-title {
  color: #141416;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 12px !important;

  [data-theme="dark"] &{
    color: #fff;
  }
}

.driver-popover.driverjs-theme  .driver-popover-description{
  width: 377px;
  color: #6E6E71;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height:24px;
  margin-bottom: 24px;

  [data-theme="dark"] & {
    color: #B5B5B5;
  }
}

.driver-popover.driverjs-theme  .driver-popover-close-btn{
    all: unset; 
    position: absolute;
    top: 10px;
    right: 10px;
    width: 12px;
    height: 12px;
    font-weight: normal;
    font-size: 25px;
    text-align: center;
    color: #141416;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.2s ease;
    z-index: 10;
    margin-top: 10px;
    margin-right: 10px;
    padding: 6px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    

    [data-theme="dark"] &{
      background-color: #000;
      color: #fff;
      border: 1px solid #fff;
    }
}

.driver-popover-navigation-btns .driver-popover-next-btn{
  display: flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--maincolor);
  color: #fff;
  text-shadow: none;
  float: right;
  border: none;
}

.driver-popover-navigation-btns .driver-popover-prev-btn{
  display: flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color:var(--maincolor);
  border-radius: 8px;
  text-shadow: none;
  float: right;
  border: 1px solid var(--maincolor);
  margin-right: 10px;

  [data-theme="dark"] &{
    background-color: transparent;
    color: #fff;
    border: 1px solid #878787;
  }
}

.driver-popover-navigation-btns .driver-popover-next-btn:hover{
  background: var(--maincolor);
  color: #fff;
  opacity: 0.8;
}

.driver-popover-footer{
  width: 100%;
}

.driver-popover-footer span{
  color: #6E6E71;

  [data-theme="dark"] &{
    color: #B5B5B5;
  }

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.driver-popover-footer button{
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  border: none;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #fff;

  [data-theme="dark"] &{
    border-left-color: #000;
  }
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #fff;
  [data-theme="dark"] &{
    border-right-color: #000;
  }
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #fff;
  [data-theme="dark"] &{
    border-top-color: #000;
  }
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #fff;
  [data-theme="dark"] &{
    border-bottom-color: #000;
  }
}

@media (max-width: 768px) {
  .driver-popover.driverjs-theme {
    max-width: 94%;
    padding: 20px;
  }
}
</style>